<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <components
          :is="tab.component"
          :popupParam.sync="popupParam"
          :equipData.sync="equipData"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
          @rankChange="rankChange"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
        psmFlag: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'equipmentInfo',
      equipData: {
        plantCd: '',  // 사업장코드 FACTCD
        equipmentTypeCd: '',  // 설비유형 코드 MECHKIND
        equipmentTypeName: '',  // 설비유형명 MECHKINDNM
        equipmentCd: '',  // 설비코드일련번호 MECHCD
        equipmentNo: '',  // 설비관리번호 MECHSER
        equipmentName: '',  // 설비명 MECHNM
        subProcessCd: '',  // 공정 GONGCD
        wccd: '',  // 라인코드 WCCD
        managementDepts: '',  // 관리부서 MNGDEPCD
        maker: '',  // 메이커
        model: '',  // 모델
        spec: '',  // 규격
        buycustcd: '',  // 도입처
        buycusttel: '',  // 도입처연락처
        ascustcd: '',  // AS업체
        ascusttel: '',  // AS업체연락처
        levels: '',  // 등급
        buydate: '',  // 구입일자
        setdate: '',  // 설치일자
        enddate: '',  // 폐기일자
        setarea: '',  // 설치면적
        weight: '',  // 중량
        epower: '',  // 전력용량(KW)
        status: '',  // 설비상태
        statusnm: '',  // 설비상태명
        testyn: '',  // 법정검사여부
        period: '',  // 주기단위
        periodnm: '',  // 주기단위명
        remark: '',  // 비고
        bgmnyn: '',  // 비가동관리여부
        monitorno: '',  // 모니터링No.
        basegdtime: '',  // 기초 가동시간(분)
        gpmnyn: '',  // 지표관리여부
        assetno: '',  // 자산번호
        buyamt: '',  // 구입금액
        plc: '',  // PLC
        nc: '',  // NC
        pannel: '',  // 터치판넬
        plcyn: '',  // PLC유무
        ncyn: '',  // NC유무
        pannelyn: '',  // 터치판넬유무
        ldate: '',  // 최근수정일시
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [
        // 설비 기본정보
        { name: 'equipmentInfo', icon: 'info', label: 'LBL0003286', component: () => import(`${'./equipmentInfo.vue'}`) }
      ]
      return _items;
    } 
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.equipment.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {equipmentCd: this.popupParam.equipmentCd, plantCd: this.popupParam.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.equipData, _result.data);
        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    },
    rankChange() {
      this.getDetail();
    }
  }
};
</script>